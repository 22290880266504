// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // Načtení uživatelských údajů z Firestore
        const userRef = doc(db, "users", firebaseUser.uid);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();

          // Zkombinování uživatelských údajů z Firebase Authentication a Firestore
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            displayName: userData.displayName || "",
            surname: userData.surname || "",
            gender: userData.gender || "",
            birthDate: userData.birthDate || "",
            height: userData.height || "",
            weight: userData.weight || "",
            mixPoints: userData.mixPoints || 0,
            kingQueenPoints: userData.kingQueenPoints || 0,
            isAdmin: userData.isAdmin || false, // Zahrnutí isAdmin příznaku
          });
        } else {
          // Pokud uživatelský dokument neexistuje, nastavíme jen základní údaje z Firebase
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            displayName: firebaseUser.displayName || "",
            surname: "",
            gender: "",
            birthDate: "",
            height: "",
            weight: "",
            mixPoints: 0,
            kingQueenPoints: 0,
            isAdmin: false,
          });
        }
      } else {
        // Pokud není uživatel přihlášen, resetujeme user stav
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const logout = () => {
    signOut(auth).catch((error) => console.error("Error signing out:", error));
  };

  return (
    <AuthContext.Provider value={{ user, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
