import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useAuth } from "../contexts/AuthContext";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";

function Admin() {
  const [openRows, setOpenRows] = useState({});
  const [futureTournaments, setFutureTournaments] = useState([]);
  const [playedTournaments, setPlayedTournaments] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [updatedPlaces, setUpdatedPlaces] = useState({});
  const [editPlacements, setEditPlacements] = useState(false);

  useEffect(() => {
    const fetchTournaments = async () => {
      const db = getFirestore();
      const kingTournamentsSnapshot = await getDocs(
        collection(db, "king_tournaments")
      );
      const mixTournamentsSnapshot = await getDocs(
        collection(db, "mix_tournaments")
      );

      const allTournaments = [
        ...kingTournamentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          category: "king",
          ...doc.data(),
        })),
        ...mixTournamentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          category: "mix",
          ...doc.data(),
        })),
      ];

      const today = new Date();
      const future = [];
      const played = [];

      allTournaments.forEach((tournament) => {
        if (new Date(tournament.date) >= today) {
          future.push(tournament);
        } else {
          played.push(tournament);
        }
      });

      setFutureTournaments(future);
      setPlayedTournaments(played);
    };

    const fetchUsers = async () => {
      const db = getFirestore();
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersList = usersSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          displayName: data.displayName,
          surname: data.surname,
          gender: data.gender || "undefined",
          mixPoints: data.mixPoints || 0,
          kingQueenPoints: data.kingQueenPoints || 0,
          birthYear: doc.data().birthDate
            ? doc.data().birthDate.substring(0, 4)
            : "Unknown",
          height: doc.data().height,
        };
      });
      setUsers(usersList);
    };

    fetchTournaments();
    fetchUsers();
  }, []);

  const handleExpandClick = (index, section) => {
    setOpenRows((prev) => ({
      ...prev,
      [section]: { ...prev[section], [index]: !prev[section]?.[index] },
    }));
  };

  const handlePlaceChange = (tournamentId, teamIndex, value) => {
    setUpdatedPlaces((prev) => ({
      ...prev,
      [tournamentId]: {
        ...prev[tournamentId],
        [teamIndex]: value,
      },
    }));
  };

  const savePlaces = async (tournamentId, category, teams, tournament) => {
    const updatedTeams = teams.map((team, index) => ({
      ...team,
      place: Number(updatedPlaces[tournamentId]?.[index]) || team.place,
    }));

    const db = getFirestore();
    const tournamentRef = doc(db, `${category}_tournaments`, tournamentId);

    // Update the tournament teams in Firestore
    await updateDoc(tournamentRef, {
      teams: updatedTeams,
    });

    // Update player profiles with tournament data
    for (const team of updatedTeams) {
      const player1Id = team.player1;
      const player2Id = team.player2;

      const player1Ref = doc(db, "users", player1Id);
      const player2Ref = doc(db, "users", player2Id);

      const player1Snapshot = await getDoc(player1Ref);
      const player2Snapshot = await getDoc(player2Ref);

      const tournaments1 = player1Snapshot.data()?.tournaments || [];
      const tournaments2 = player2Snapshot.data()?.tournaments || [];

      const points = 52 - team.place * 2;

      const updatePlayerTournaments = (tournaments, playerId, partnerId) => {
        const existingTournamentIndex = tournaments.findIndex(
          (t) =>
            t.tournamentName === tournament.name &&
            t.date === tournament.date &&
            t.category === tournament.category
        );

        if (existingTournamentIndex > -1) {
          tournaments[existingTournamentIndex] = {
            ...tournaments[existingTournamentIndex],
            place: team.place,
            points: points,
          };
        } else {
          tournaments.push({
            tournamentName: tournament.name,
            category: tournament.category,
            date: tournament.date,
            place: team.place,
            points: points,
            partner: partnerId,
          });
        }
      };

      updatePlayerTournaments(tournaments1, player1Id, player2Id);
      updatePlayerTournaments(tournaments2, player2Id, player1Id);

      await updateDoc(player1Ref, {
        tournaments: tournaments1,
        ...(category === "mix" && {
          mixPoints: calculateTopPoints(tournaments1),
        }),
        ...(category === "king" && {
          kingQueenPoints: calculateTopPoints(tournaments1),
        }),
      });

      await updateDoc(player2Ref, {
        tournaments: tournaments2,
        ...(category === "mix" && {
          mixPoints: calculateTopPoints(tournaments2),
        }),
        ...(category === "king" && {
          kingQueenPoints: calculateTopPoints(tournaments2),
        }),
      });
    }

    alert("Umístění týmů bylo úspěšně uloženo.");
    setUpdatedPlaces((prev) => ({ ...prev, [tournamentId]: {} }));
    setPlayedTournaments((prev) =>
      prev.map((t) =>
        t.id === tournamentId ? { ...t, teams: updatedTeams } : t
      )
    );
  };
  const calculateTopPoints = (tournaments) =>
    tournaments
      .map((t) => t.points || 0)
      .sort((a, b) => b - a)
      .slice(0, 4)
      .reduce((a, b) => a + b, 0);
  const handleRegisterTeam = async (tournamentId, category, teams) => {
    if (
      !selectedUser[tournamentId]?.player1 ||
      !selectedUser[tournamentId]?.player2
    ) {
      alert("Vyberte oba hráče.");
      return;
    }

    const player1Points =
      category === "mix"
        ? users.find((u) => u.id === selectedUser[tournamentId]?.player1)
            ?.mixPoints || 0
        : users.find((u) => u.id === selectedUser[tournamentId]?.player1)
            ?.kingQueenPoints || 0;
    const player2Points =
      category === "mix"
        ? users.find((u) => u.id === selectedUser[tournamentId]?.player2)
            ?.mixPoints || 0
        : users.find((u) => u.id === selectedUser[tournamentId]?.player2)
            ?.kingQueenPoints || 0;

    console.log("selectedUser[tournamentId]?.player1");
    console.log(selectedUser[tournamentId]?.player1);
    console.log("selectedUser[tournamentId]?.player2");
    console.log(selectedUser[tournamentId]?.player2);
    console.log("category");
    console.log(category);
    console.log("player1Points");
    console.log(player1Points);
    console.log("player2Points");
    console.log(player2Points);

    const team = {
      player1: selectedUser[tournamentId]?.player1,
      player2: selectedUser[tournamentId]?.player2,
      teamPoints: player1Points + player2Points,
      registeredAt: new Date(),
      place: 0,
    };

    const allTeams = [...(teams || []), team].sort(
      (a, b) => b.teamPoints - a.teamPoints || a.registeredAt - b.registeredAt
    );

    const db = getFirestore();
    const tournamentRef = doc(db, `${category}_tournaments`, tournamentId);

    await updateDoc(tournamentRef, {
      teams: allTeams,
    });

    alert("Tým byl úspěšně přihlášen.");
    setSelectedUser({});
    window.location.reload();
  };

  const handleUnregisterTeam = async (tournamentId, category, team) => {
    const db = getFirestore();
    const tournamentRef = doc(db, `${category}_tournaments`, tournamentId);

    await updateDoc(tournamentRef, {
      teams: arrayRemove(team),
    });

    alert("Tým byl úspěšně odhlášen.");
    window.location.reload();
  };

  return (
    <Box
      sx={{ backgroundColor: "#F5F5F5", color: "#333333", minHeight: "100vh" }}
    >
      <Navbar />
      <Box sx={{ padding: "20px", flex: 1 }}>
        <Typography variant="h3" sx={{ color: "#FF5722" }}>
          Admin - Správa Turnajů
        </Typography>
        <Link to="/create-tournament" style={{ textDecoration: "none" }}>
          <Button
            sx={{ color: "#FFFFFF", background: "#FF5722", marginBottom: 3 }}
          >
            Vytvořit turnaj
          </Button>
        </Link>

        {/* Future Tournaments */}
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Budoucí Turnaje
        </Typography>
        <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Název</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Kapacita</TableCell>
                <TableCell>Počet přihlášených týmů</TableCell>
                <TableCell>Akce</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {futureTournaments.map((tournament, index) => (
                <React.Fragment key={tournament.id}>
                  <TableRow>
                    <TableCell>{tournament.name}</TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat("cs-CZ", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }).format(new Date(tournament.date))}
                    </TableCell>
                    <TableCell>{tournament.capacity}</TableCell>
                    <TableCell>{tournament.teams?.length || 0}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleExpandClick(index, "future")}
                        aria-expanded={openRows?.future?.[index]}
                        aria-label="show more"
                      >
                        {openRows?.future?.[index] ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Collapse in={openRows?.future?.[index]}>
                        <Box sx={{ padding: 2 }}>
                          <Typography variant="h6">Přihlášené týmy:</Typography>
                          {tournament.teams?.map((team, idx) => {
                            const player1 = users.find(
                              (u) => u.id === team.player1
                            );
                            const player2 = users.find(
                              (u) => u.id === team.player2
                            );

                            return (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 1,
                                }}
                              >
                                <Typography sx={{ flex: 1 }}>
                                  {`${idx + 1}. ${
                                    player1?.displayName || "Neznámý hráč"
                                  } ${player1?.surname || ""} / ${
                                    player2?.displayName || "Neznámý hráč"
                                  } ${player2?.surname || ""} (${
                                    team.teamPoints || 0
                                  } bodů)`}
                                </Typography>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() =>
                                    handleUnregisterTeam(
                                      tournament.id,
                                      tournament.category,
                                      team
                                    )
                                  }
                                >
                                  Odhlásit
                                </Button>
                              </Box>
                            );
                          })}
                          {/* Register New Team */}
                          <Typography variant="h6" sx={{ marginTop: 2 }}>
                            Přihlásit nový tým:
                          </Typography>

                          <Autocomplete
                            options={users}
                            getOptionLabel={(option) =>
                              `${option.displayName} ${option.surname}`
                            }
                            value={
                              users.find(
                                (u) =>
                                  u.id === selectedUser[tournament.id]?.player1
                              ) || null
                            }
                            onChange={(e, newValue) =>
                              setSelectedUser({
                                ...selectedUser,
                                [tournament.id]: {
                                  ...selectedUser[tournament.id],
                                  player1: newValue?.id || "",
                                },
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Hráč 1"
                                variant="outlined"
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {" "}
                                {/* Ensure a unique key is used */}
                                {`${option.displayName} ${option.surname}`}
                              </li>
                            )}
                            sx={{ width: 300, mb: 1 }}
                          />

                          <Autocomplete
                            options={users}
                            getOptionLabel={(option) =>
                              `${option.displayName} ${option.surname}`
                            }
                            value={
                              users.find(
                                (u) =>
                                  u.id === selectedUser[tournament.id]?.player2
                              ) || null
                            }
                            onChange={(e, newValue) =>
                              setSelectedUser({
                                ...selectedUser,
                                [tournament.id]: {
                                  ...selectedUser[tournament.id],
                                  player2: newValue?.id || "",
                                },
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Hráč 2"
                                variant="outlined"
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.id}>
                                {" "}
                                {/* Ensure a unique key is used */}
                                {`${option.displayName} ${option.surname}`}
                              </li>
                            )}
                            sx={{ width: 300, mb: 1 }}
                          />

                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleRegisterTeam(
                                tournament.id,
                                tournament.category === "mix" ? "mix" : "king",
                                tournament.teams
                              )
                            }
                          >
                            Přihlásit tým
                          </Button>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Played Tournaments */}
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Odehrané Turnaje
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Název</TableCell>
                <TableCell>Datum</TableCell>
                <TableCell>Kapacita</TableCell>
                <TableCell>Počet přihlášených týmů</TableCell>
                <TableCell>Akce</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {playedTournaments.map((tournament, index) => (
                <React.Fragment key={tournament.id}>
                  <TableRow>
                    <TableCell>{tournament.name}</TableCell>
                    <TableCell>
                      {new Intl.DateTimeFormat("cs-CZ", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }).format(new Date(tournament.date))}
                    </TableCell>
                    <TableCell>{tournament.capacity}</TableCell>
                    <TableCell>{tournament.teams?.length || 0}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleExpandClick(index, "played")}
                        aria-expanded={openRows?.played?.[index]}
                        aria-label="show more"
                      >
                        {openRows?.played?.[index] ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Collapse in={openRows?.played?.[index]}>
                        <Box sx={{ padding: 2 }}>
                          <Typography variant="h6">Přihlášené týmy:</Typography>
                          {tournament.teams?.map((team, idx) => {
                            const player1 = users.find(
                              (u) => u.id === team.player1
                            );
                            const player2 = users.find(
                              (u) => u.id === team.player2
                            );

                            return (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 1,
                                }}
                              >
                                <Typography sx={{ flex: 1 }}>
                                  {`${player1?.displayName || "Neznámý hráč"} ${
                                    player1?.surname || ""
                                  } / ${
                                    player2?.displayName || "Neznámý hráč"
                                  } ${player2?.surname || ""}`}
                                </Typography>
                                {editPlacements ? (
                                  <TextField
                                    label="Umístění"
                                    type="number"
                                    value={
                                      updatedPlaces[tournament.id]?.[idx] ??
                                      team.place ??
                                      ""
                                    }
                                    onChange={(e) =>
                                      handlePlaceChange(
                                        tournament.id,
                                        idx,
                                        e.target.value
                                      )
                                    }
                                    sx={{ width: 100, marginRight: 2 }}
                                  />
                                ) : (
                                  <Typography>
                                    {updatedPlaces[tournament.id]?.[idx] ??
                                      team.place ??
                                      ""}
                                  </Typography>
                                )}
                              </Box>
                            );
                          })}
                          {editPlacements ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                savePlaces(
                                  tournament.id,
                                  tournament.category,
                                  tournament.teams,
                                  tournament
                                );
                                setEditPlacements(false);
                              }}
                            >
                              Uložit umístění
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setEditPlacements(true)}
                            >
                              Upravit umístění
                            </Button>
                          )}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </Box>
  );
}

export default Admin;
