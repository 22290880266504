import React, { useState } from "react";
import { TextField, Button, Box, Typography, Link } from "@mui/material";
import { sendPasswordResetEmail, auth } from "../firebase";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Check your email for the password reset link.");
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5", // Světle šedá pozadí
        color: "#333333", // Tmavě šedý text
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          backgroundColor: "#FFFFFF",
          padding: 4,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "2px solid #333333", // Tmavě šedý okraj
        }}
      >
        <Typography variant="h4" sx={{ color: "#FF5722", mb: 2 }}>
          Reset Password
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        {message && <Typography color="primary">{message}</Typography>}
        <form onSubmit={handleResetPassword}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: "#FF5722", // Oranžová
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#333333", // Tmavě šedá hover
              },
            }}
          >
            Reset Password
          </Button>
        </form>
        <Box mt={2}>
          <Typography>
            Go back to{" "}
            <Link href="/" underline="hover" sx={{ color: "#FF5722" }}>
              Home
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
