import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  applyActionCode,
  signInWithEmailAndPassword,
  getAuth,
} from "firebase/auth"; // Import Firebase auth funkcí
import { Box, Typography, CircularProgress } from "@mui/material";
import { auth } from "../firebase"; // Váš Firebase config

function EmailVerification() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Ověřuji váš email...");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const oobCode = searchParams.get("oobCode");

    const verifyEmailAndSignIn = async () => {
      if (oobCode) {
        try {
          // Ověření emailu
          await applyActionCode(auth, oobCode);
          setMessage("Email úspěšně ověřen! Přihlašuji vás...");

          // Získej uživatele, který byl právě ověřen
          const currentUser = auth.currentUser;

          // Automatické přihlášení po verifikaci (pokud již existuje session)
          if (currentUser) {
            setTimeout(() => {
              navigate("/"); // Přesměrování na domovskou stránku
            }, 2000); // Po krátké pauze
          } else {
            setMessage("Chyba při přihlášení. Přihlaste se prosím manuálně.");
          }
        } catch (error) {
          setMessage("Ověření emailu selhalo. Zkuste to znovu.");
          console.error("Error verifying email:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setMessage("Chybí kód pro ověření.");
        setLoading(false);
      }
    };

    verifyEmailAndSignIn();
  }, [searchParams, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {message}
      </Typography>
      {loading && <CircularProgress />}
    </Box>
  );
}

export default EmailVerification;
