import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Mix from "./pages/MixSeriesPage";
import KingQueen from "./pages/KingQueenPage";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import EmailVerification from "./pages/EmailVerification";
import Profile from "./pages/Profile";
import Admin from "./pages/Admin";
import CreateTournament from "./pages/CreateTournament";
import EditTournament from "./pages/EditTournament";
import Ranking from "./pages/Ranking";
import Results from "./pages/Results";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Homepage />} />
          <Route path="/mixova-serie" element={<Mix />} />
          <Route path="/king-queen" element={<KingQueen />} />
          {/* <Route path="/o-nas" element={<AboutUs />} /> */}
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/profile/:uid" element={<Profile />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/create-tournament" element={<CreateTournament />} />
          <Route path="/edit-tournament" element={<EditTournament />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/results" element={<Results />} />
          <Route path="*" element={<h1>Page not found</h1>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
