import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import Navbar from "../components/Navbar"; // Importujeme Navbar
import Footer from "../components/Footer"; // Importujeme Footer

function Contact() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensure it covers the full height of the screen
      }}
    >
      <Navbar /> {/* Přidáme Navbar */}
      <Box
        sx={{
          textAlign: "center",
          padding: "20px",
          backgroundImage: "url(/contact-background.jpg)", // URL pozadí v public
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h3" component="h1" sx={{ color: "white", mb: 4 }}>
          Kontaktujte nás
        </Typography>
      </Box>
      <Container sx={{ flexGrow: 1, mt: 4, pb: 8 }}>
        {" "}
        {/* Added padding-bottom */}
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h5" gutterBottom>
            Kontaktní informace
          </Typography>
          <Typography variant="body1" paragraph>
            Pokud máte jakékoli dotazy nebo potřebujete více informací,
            neváhejte mě kontaktovat prostřednictvím níže uvedených kontaktních
            údajů.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Jméno organizátora
          </Typography>
          <Typography variant="body1" paragraph>
            Richard Vacenovský
          </Typography>

          <Typography variant="h6" gutterBottom>
            E-mail
          </Typography>
          <Typography variant="body1" paragraph>
            <a
              href="mailto:vaceri@seznam.cz"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              vaceri@seznam.cz
            </a>
          </Typography>

          <Typography variant="h6" gutterBottom>
            Telefon
          </Typography>
          <Typography variant="body1" paragraph>
            +420 775 270 398
          </Typography>

          <Typography variant="h6" gutterBottom>
            Sociální sítě
          </Typography>
          <Typography variant="body1">
            <a
              href="https://www.facebook.com/vaceri1"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Facebook
            </a>
            <br />
            <a
              href="https://www.instagram.com/richard_vacenovsky/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Instagram
            </a>
          </Typography>
        </Paper>
      </Container>
      <Box
        sx={{
          mt: "auto", // Ensures that the footer stays at the bottom of the page
        }}
      >
        <Footer /> {/* Přidáme Footer */}
      </Box>
    </Box>
  );
}

export default Contact;
