import React from "react";
import { Box, Typography, Container, Paper } from "@mui/material";
import Navbar from "../components/Navbar"; // Importujeme Navbar
import Footer from "../components/Footer"; // Importujeme Footer

function AboutUs() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Ensure it covers the full height of the screen
      }}
    >
      <Navbar /> {/* Přidáme Navbar */}
      <Box
        sx={{
          textAlign: "center",
          padding: "20px",
          backgroundImage: "url(/about-background.jpg)", // URL pozadí v public
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography variant="h3" component="h1" sx={{ color: "white", mb: 4 }}>
          O nás
        </Typography>
      </Box>
      <Container sx={{ flexGrow: 1, mt: 4 }}>
        {" "}
        {/* Pushes content up */}
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h5" gutterBottom>
            Kdo jsem
          </Typography>
          <Typography variant="body1" paragraph>
            Beach volejbalový nadšenec a organizátor omylem ajťákem.
          </Typography>
        </Paper>
      </Container>
      <Footer /> {/* Přidáme Footer */}
    </Box>
  );
}

export default AboutUs;
