import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  Button,
} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";

function Ranking() {
  const [players, setPlayers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("mix"); // Default category

  useEffect(() => {
    const fetchPlayers = async () => {
      const db = getFirestore();
      const playersSnapshot = await getDocs(collection(db, "users"));
      const playersList = playersSnapshot.docs.map((doc) => ({
        id: doc.id,
        displayName: doc.data().displayName,
        surname: doc.data().surname,
        mixPoints: doc.data().mixPoints || 0,
        kingQueenPoints: doc.data().kingQueenPoints || 0,
        gender: doc.data().gender || "unknown",
      }));
      setPlayers(playersList);
    };

    fetchPlayers();
  }, []);

  const getRankedPlayers = (category) => {
    if (category === "mix") {
      return players.sort((a, b) => b.mixPoints - a.mixPoints);
    } else if (category === "king") {
      return players
        .filter((player) => player.gender === "male")
        .sort((a, b) => b.kingQueenPoints - a.kingQueenPoints);
    } else if (category === "queen") {
      return players
        .filter((player) => player.gender === "female")
        .sort((a, b) => b.kingQueenPoints - a.kingQueenPoints);
    }
  };

  const rankedPlayers = getRankedPlayers(selectedCategory);

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            color: "#FF5722",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Žebříček hráčů
        </Typography>

        <ButtonGroup variant="contained" sx={{ marginBottom: 4 }}>
          <Button
            color={selectedCategory === "mix" ? "primary" : "secondary"}
            onClick={() => setSelectedCategory("mix")}
          >
            Mixy
          </Button>
          <Button
            color={selectedCategory === "king" ? "primary" : "secondary"}
            onClick={() => setSelectedCategory("king")}
          >
            King
          </Button>
          <Button
            color={selectedCategory === "queen" ? "primary" : "secondary"}
            onClick={() => setSelectedCategory("queen")}
          >
            Queen
          </Button>
        </ButtonGroup>

        <TableContainer component={Paper} sx={{ maxWidth: "900px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "15%", textAlign: "center" }}>
                  Pořadí
                </TableCell>
                <TableCell sx={{ width: "70%", textAlign: "left" }}>
                  Jméno
                </TableCell>
                <TableCell sx={{ width: "15%", textAlign: "center" }}>
                  Body
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rankedPlayers.map((player, index) => (
                <TableRow key={player.id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="left">
                    <Link
                      to={`/profile/${player.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {`${player.displayName} ${player.surname}`}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {selectedCategory === "mix"
                      ? player.mixPoints
                      : player.kingQueenPoints}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Footer />
    </Box>
  );
}

export default Ranking;
