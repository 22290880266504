import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

function CreateTournament() {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [capacity, setCapacity] = useState("");
  const [location, setLocation] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [registrationDeadline, setRegistrationDeadline] = useState("");
  const [partnerChangeDeadline, setPartnerChangeDeadline] = useState("");
  const [organizer, setOrganizer] = useState("");
  const [type, setType] = useState("mix"); // default to "mix"
  const [category, setCategory] = useState("king"); // New state for category
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    // Update category based on type whenever type changes
    setCategory(type === "mix" ? "mix" : category);
  }, [type]); // Dependency array ensures this runs when `type` changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, `${type}_tournaments`), {
        name,
        date,
        capacity: parseInt(capacity),
        location,
        entryFee,
        registrationDeadline,
        partnerChangeDeadline,
        organizer,
        category, // Save the new category field
        additionalInfo,
        link,
        teams: [],
      });

      // Reset form and show success message
      setName("");
      setDate("");
      setCapacity("");
      setLocation("");
      setEntryFee("");
      setRegistrationDeadline("");
      setPartnerChangeDeadline("");
      setOrganizer("");
      setAdditionalInfo("");
      setLink("");
      setType("");
      setCategory(""); // Reset category
      setSuccess(true);
    } catch (error) {
      console.error("Error adding tournament:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box sx={{ padding: "20px", flex: 1 }}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{ color: "#FF5722" }}
        >
          Vytvořit nový turnaj
        </Typography>

        <form onSubmit={handleSubmit}>
          {/* Form fields */}
          <FormControl fullWidth sx={{ marginBottom: "16px" }}>
            <InputLabel>Typ turnaje</InputLabel>
            <Select
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Typ turnaje"
            >
              <MenuItem value="king">King/Queen</MenuItem>
              <MenuItem value="mix">Mix</MenuItem>
            </Select>
          </FormControl>

          {/* New category selection for King/Queen */}
          {type === "king" && (
            <FormControl fullWidth sx={{ marginBottom: "16px" }}>
              <InputLabel>Kategorie</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label="Kategorie"
              >
                <MenuItem value="king">King of the Court</MenuItem>
                <MenuItem value="queen">Queen of the Court</MenuItem>
              </Select>
            </FormControl>
          )}

          <TextField
            label="Název turnaje"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            label="Datum konání"
            type="date"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Kapacita hráčů"
            type="number"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={capacity}
            onChange={(e) => setCapacity(e.target.value)}
            required
          />
          <TextField
            label="Místo konání"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <TextField
            label="Startovné (na dvojici)"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={entryFee}
            onChange={(e) => setEntryFee(e.target.value)}
            required
          />
          <TextField
            label="Deadline pro přihlášky/odhlášky"
            type="date"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={registrationDeadline}
            onChange={(e) => setRegistrationDeadline(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Deadline pro změnu spoluhráče"
            type="date"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={partnerChangeDeadline}
            onChange={(e) => setPartnerChangeDeadline(e.target.value)}
            required
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Organizátor"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
            required
          />
          <TextField
            label="Doplňující informace"
            fullWidth
            multiline
            rows={4}
            sx={{ marginBottom: "16px" }}
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
          />
          <TextField
            label="Odkaz"
            fullWidth
            sx={{ marginBottom: "16px" }}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          {success && (
            <Alert severity="success" sx={{ marginBottom: 2 }}>
              Turnaj byl úspěšně vytvořen! Klikněte{" "}
              <Link href="/admin" sx={{ fontWeight: "bold" }}>
                zde
              </Link>{" "}
              pro návrat na správu turnajů.
            </Alert>
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Vytvořit turnaj"}
          </Button>
        </form>
      </Box>
      <Footer />
    </Box>
  );
}

export default CreateTournament;
