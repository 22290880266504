import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Link as LinkMui,
} from "@mui/material";
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Results() {
  const [openRows, setOpenRows] = useState({});
  const [tournaments, setTournaments] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTournaments = async () => {
      const db = getFirestore();
      const resultsSnapshot = await getDocs(collection(db, "mix_tournaments"));
      const today = new Date();

      const tournamentsList = resultsSnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((tournament) => new Date(tournament.date) < today) // Only past tournaments
        .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by most recent first

      setTournaments(tournamentsList);
      setLoading(false);
    };

    const fetchUsers = async () => {
      const db = getFirestore();
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        displayName: doc.data().displayName,
        surname: doc.data().surname,
      }));
      setUsers(usersList);
    };

    fetchTournaments();
    fetchUsers();
  }, []);

  const handleExpandClick = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: "20px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            color: "#FF5722",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Výsledky turnajů
        </Typography>

        <Box sx={{ marginTop: 4, width: "100%", maxWidth: "1200px" }}>
          <Typography variant="h4" component="div" gutterBottom>
            Zima 2024/25
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Název</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Datum</strong>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <strong>Detail</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tournaments.map((row, index) => {
                  const rowBgColor = index % 2 === 0 ? "#f9f9f9" : "#ffffff";
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          cursor: "pointer",
                          backgroundColor: rowBgColor,
                          padding: "8px 0",
                        }}
                      >
                        <TableCell>
                          <Typography variant="body1">{row.name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {new Intl.DateTimeFormat("cs-CZ", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(new Date(row.date))}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => handleExpandClick(index)}
                          >
                            Detail
                          </Button>
                        </TableCell>
                      </TableRow>

                      {/* Expanded detail section */}
                      <TableRow>
                        <TableCell
                          colSpan={3}
                          sx={{ padding: "8px", backgroundColor: rowBgColor }}
                        >
                          <Collapse in={openRows[index]}>
                            <Box
                              sx={{
                                backgroundColor: rowBgColor,
                                padding: "20px",
                                marginBottom: "20px",
                                borderRadius: "8px",
                              }}
                            >
                              {/* <Typography variant="h5" gutterBottom>
                                {row.name}
                              </Typography>
                              <Typography variant="body1" sx={{ mb: 2 }}>
                                {row.additionalInfo ||
                                  "Detaily výsledků nejsou k dispozici."}
                              </Typography> */}
                              <Typography variant="h5" gutterBottom>
                                Fotogalerie
                              </Typography>
                              <Typography variant="body1" sx={{ mb: 2 }}>
                                <LinkMui href={row.photogalery}>
                                  ODKAZ ZDE
                                </LinkMui>
                              </Typography>
                              <Typography variant="h5" sx={{ mb: 2 }}>
                                Finální umístění týmů:
                              </Typography>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <Typography variant="body1">
                                          Umístění
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body1">
                                          Hráč 1
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body1">
                                          Hráč 2
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body1">
                                          Body
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row.teams
                                      ?.sort((a, b) => a.place - b.place) // Sort by team place
                                      .map((team, idx) => {
                                        const player1 = users.find(
                                          (u) => u.id === team.player1
                                        );
                                        const player2 = users.find(
                                          (u) => u.id === team.player2
                                        );
                                        return (
                                          <TableRow key={idx} hover>
                                            {/* Place */}
                                            <TableCell>
                                              <Typography variant="body1">{`${team.place}.`}</Typography>
                                            </TableCell>

                                            {/* Player 1 Link */}
                                            <TableCell>
                                              <Link
                                                to={
                                                  player1 &&
                                                  `/profile/${player1.id}`
                                                }
                                                style={{
                                                  textDecoration: "none",
                                                  color: "inherit",
                                                  display: "block",
                                                }}
                                              >
                                                {player1 &&
                                                  `${player1.displayName} ${player1.surname}`}
                                              </Link>
                                            </TableCell>

                                            {/* Player 2 Name */}
                                            <TableCell>
                                              <Link
                                                to={
                                                  player2 &&
                                                  `/profile/${player2.id}`
                                                }
                                                style={{
                                                  textDecoration: "none",
                                                  color: "inherit",
                                                  display: "block",
                                                }}
                                              >
                                                {player2 &&
                                                  `${player2.displayName} ${player2.surname}`}
                                              </Link>
                                            </TableCell>

                                            {/* Points */}
                                            <TableCell>
                                              <Typography variant="body1">
                                                {52 - team.place * 2}
                                              </Typography>
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default Results;
