import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Link,
  MenuItem,
  Alert,
} from "@mui/material";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  auth,
  db,
  doc,
  setDoc,
} from "../firebase";
import { useNavigate } from "react-router-dom";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Hesla se neshodují.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        displayName: name,
        surname: surname,
        gender: gender,
        height: height,
        weight: weight || null,
        birthDate: birthdate,
        mixPoints: 0,
        kingQueenPoints: 0,
        isAdmin: false,
      });

      await sendEmailVerification(user);
      setError("");
      setMessage(
        "Registrace byla úspěšná! Zkontrolujte prosím svůj email a ověřte svůj účet."
      );
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          setError("Tento email je již použit.");
          break;
        case "auth/invalid-email":
          setError("Neplatná emailová adresa.");
          break;
        case "auth/weak-password":
          setError("Heslo musí obsahovat alespoň 6 znaků.");
          break;
        default:
          setError("Došlo k chybě při registraci. Zkuste to znovu.");
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          backgroundColor: "#FFFFFF",
          padding: 4,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "2px solid #333333",
        }}
      >
        <Typography variant="h4" sx={{ color: "#FF5722", mb: 2 }}>
          Registrace
        </Typography>

        <form onSubmit={handleRegister}>
          <TextField
            label="Jméno"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Příjmení"
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Pohlaví"
            select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            fullWidth
            margin="normal"
            required
          >
            <MenuItem value="male">Muž</MenuItem>
            <MenuItem value="female">Žena</MenuItem>
          </TextField>
          <TextField
            label="Výška v cm (nepovinné)"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Nepovinné"
          />
          <TextField
            label="Hmotnost v kg (nepovinné)"
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Nepovinné"
          />
          <TextField
            label="Rok narození (nepovinné)"
            type="number"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Nepovinné"
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Heslo"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Potvrdit heslo"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: "#FF5722",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#333333",
              },
            }}
          >
            Registrovat
          </Button>
        </form>
        <Box mt={2}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}
          <Typography>
            Přesunout na{" "}
            <Link href="/login" underline="hover" sx={{ color: "#FF5722" }}>
              Přihlášení
            </Link>
          </Typography>
          <Typography>
            Zpět na{" "}
            <Link href="/" underline="hover" sx={{ color: "#FF5722" }}>
              Hlavní stránku
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
