import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeIcon from "@mui/icons-material/LightMode";

function Navbar() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const isActive = (path) => location.pathname === path;

  const buttonStyles = (isActive) => ({
    color: isActive ? "#FF5722" : "#FFFFFF",
    borderBottom: isActive ? "2px solid #FF5722" : "none",
    fontWeight: isActive ? "bold" : "normal",
    "&:hover": {
      backgroundColor: "# ",
    },
    fontSize: "14px",
  });

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const handleProfileClick = () => {
    navigate(`/profile/${user.uid}`);
    handleClose();
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const navItems = (
    <>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button color="inherit" sx={buttonStyles(isActive("/"))}>
          Domů
        </Button>
      </Link>
      <Link to="/kontakt" style={{ textDecoration: "none" }}>
        <Button color="inherit" sx={buttonStyles(isActive("/kontakt"))}>
          Kontakt
        </Button>
      </Link>
      <Link to="/ranking" style={{ textDecoration: "none" }}>
        <Button color="inherit" sx={buttonStyles(isActive("/ranking"))}>
          Žebříčky
        </Button>
      </Link>
      <Link to="/results" style={{ textDecoration: "none" }}>
        <Button color="inherit" sx={buttonStyles(isActive("/results"))}>
          Výsledky
        </Button>
      </Link>
      {user && user.isAdmin && (
        <Link to="/admin" style={{ textDecoration: "none" }}>
          <Button color="inherit" sx={buttonStyles(isActive("/admin"))}>
            Správa turnajů
          </Button>
        </Link>
      )}
    </>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "#333333" }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex", // Ensures that the text and icon align correctly
              alignItems: "center", // Vertically align the text and the icon
              color: hovered ? "#FF5722" : "#FFFFFF", // Change color on hover
              fontWeight: "bold",
              cursor: "pointer",
              fontFamily: "'Montserrat', sans-serif", // Customize the font family
              letterSpacing: "2px", // Add spacing between letters
            }}
            onClick={() => navigate("/")}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/passion_beach5.png`} // Replace with your image path or URL
              alt="Descriptive Text"
              sx={{
                // width: "100%", // Adjust to your desired size or use a specific value like '300px'
                maxHeight: "80px", // Limits the width on larger screens
                height: "auto", // Keeps the aspect ratio
                borderRadius: 2, // Rounds the corners if desired
              }}
            />
          </Typography>
        </Box>

        {/* <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              display: "flex", // Ensures that the text and icon align correctly
              alignItems: "center", // Vertically align the text and the icon
              color: hovered ? "#FF5722" : "#FFFFFF", // Change color on hover
              fontWeight: "bold",
              fontStyle: "italic",
              cursor: "pointer",
              fontFamily: "'Raleway', sans-serif", // Customize the font family
              letterSpacing: "2px", // Add spacing between letters
            }}
            onClick={() => navigate("/")}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            PASSI
            <LightModeIcon
              sx={{
                fontSize: "1.5rem", // Adjust icon size
                verticalAlign: "middle", // Ensure vertical alignment
                color: hovered ? "#FF5722" : "#FFFFFF", // Change color on hover
              }}
            />
            N BEACH
          </Typography>
        </Box> */}

        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box sx={{ width: 250 }} onClick={toggleDrawer(false)}>
                <List>
                  {/* Navigation items for the Drawer menu */}
                  <ListItem button component={Link} to="/">
                    <ListItemText primary="Domů" />
                  </ListItem>
                  <ListItem button component={Link} to="/kontakt">
                    <ListItemText primary="Kontakt" />
                  </ListItem>
                  <ListItem button component={Link} to="/ranking">
                    <ListItemText primary="Žebříčky" />
                  </ListItem>
                  <ListItem button component={Link} to="/results">
                    <ListItemText primary="Výsledky" />
                  </ListItem>
                  {user && user.isAdmin && (
                    <ListItem button component={Link} to="/admin">
                      <ListItemText primary="Správa turnajů" />
                    </ListItem>
                  )}
                  {user ? (
                    <>
                      <ListItem button onClick={handleProfileClick}>
                        <ListItemText primary="Zobrazit profil" />
                      </ListItem>
                      <ListItem button onClick={handleLogout}>
                        <ListItemText
                          primary="Odhlásit se"
                          sx={{ color: "#FF0000" }}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <ListItem button component={Link} to="/login">
                      <ListItemText primary="Přihlásit se" />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
            {navItems}
            {user ? (
              <>
                <IconButton
                  color="inherit"
                  onClick={handleProfileMenuClick}
                  sx={buttonStyles(isActive(`/profile/${user.uid}`))}
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfileClick}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#333333",
                      }}
                    >
                      Zobrazit profil
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#FF0000",
                      }}
                    >
                      Odhlásit se
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button color="inherit" sx={buttonStyles(false)}>
                  Přihlásit se
                </Button>
              </Link>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
