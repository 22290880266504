import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Link, Alert } from "@mui/material";
import { signInWithEmailAndPassword, auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    // Prevent auto-login after registration
  }, [user]);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError("Prosím ověřte svůj email před přihlášením.");
        return;
      }

      // Clear error if login is successful
      setError("");
      navigate("/");
    } catch (error) {
      // Log the error code to the console for debugging
      console.log("Error code:", error.code);

      // Handling different Firebase auth error codes with user-friendly messages
      switch (error.code) {
        case "auth/user-not-found":
          setError("Tento email není registrován.");
          break;
        case "auth/invalid-email":
          setError("Neplatná emailová adresa.");
          break;
        case "auth/too-many-requests":
          setError(
            "Příliš mnoho neúspěšných pokusů o přihlášení. Zkuste to prosím později."
          );
          break;
        default:
          setError(
            "Nastala chyba při přihlašování (nesprávný email nebo heslo). Zkuste to znovu."
          );
          break;
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#333333",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 500,
          backgroundColor: "#FFFFFF",
          padding: 4,
          borderRadius: 2,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          border: "2px solid #333333",
        }}
      >
        <Typography variant="h4" sx={{ color: "#FF5722", mb: 2 }}>
          Přihlášení
        </Typography>

        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Heslo"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              backgroundColor: "#FF5722",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#333333",
              },
            }}
          >
            Přihlásit se
          </Button>
        </form>

        <Box mt={2}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Typography>
            Nemáte účet?{" "}
            <Link href="/register" underline="hover" sx={{ color: "#FF5722" }}>
              Registrujte se
            </Link>
          </Typography>
          <Typography>
            Zapomněli jste heslo?{" "}
            <Link
              href="/forgot-password"
              underline="hover"
              sx={{ color: "#FF5722" }}
            >
              Obnovit heslo
            </Link>
          </Typography>
          <Typography>
            Zpět na{" "}
            <Link href="/" underline="hover" sx={{ color: "#FF5722" }}>
              Hlavní stránku
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
